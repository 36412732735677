import {UmEntity, UmQuery} from "../core";



/**
 * 港口(Entity)
 * @author syj
 * @date 2021-04-09 14:53
 */
export class PortEntity extends UmEntity {
	//国家主键(c_country)
	public countryKey?: number;
	//国家代码
	public countryCode?: string;
	//联合国港口代码
	public unCode?: string;
	//名称(EN)
	public name?: string;
	//
	public timeZone?: number;
	//port类型
	public portType?: string;
	//经度
	public lon?: number;
	//纬度
	public lat?: number;
	//是否有效
	public available?: number;
}


/**
 * 港口(Query)
 * @author syj
 * @date 2021-04-09 14:53
 */
export class PortQuery extends UmQuery {

	public name?:string;
}
