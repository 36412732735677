import Vue from "vue";
import {PortEntity} from "@/core/entity/common/port";
import display from "@/configure/display/common/port";
import service from "@/core/service";
import {Verifiable} from "@/core/types/vuetify";

export default Vue.extend({
    components: {
    },
    data: () => ({
        key: void 0 as undefined | number,
        loading: false,
        readonly: false,
        saving: false,
        data: new PortEntity(),
        display
    }),
    async mounted() {
        switch (this.$route.name) {
            case "port.add":
                break;
            case "port.load":
                this.readonly = true;
            case "port.edit":
                this.key = Number(this.$route.params.key);
                await this.load();
                break;
        }
    },
    methods: {
        async load() {
            this.loading = true;
            try {
                this.data = await service.port.load(this.key!);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if ((<Verifiable>this.$refs.form).validate()) {
                this.saving = true;
                try {
                    if (this.key === void 0) {
                        this.data = await service.port.add(this.data);
                        this.key = this.data.key;
                    } else {
                        await service.port.save(this.data);
                        this.data = await service.port.load(this.key);
                    }
                    this.$message("保存成功!", "success");
                } finally {
                    this.saving = false;
                }
            }else
                this.$message("有数据填写错误!", "error");
        }
    }
});
